
import { defineComponent, onBeforeMount, ref } from 'vue';
import {
  IonList,
  IonItem,
  IonThumbnail,
  IonLabel,
  IonSkeletonText,
} from '@ionic/vue';

import firebase from 'firebase';
import { onBeforeRouteUpdate, useRoute, useRouter } from 'vue-router';

export default defineComponent({
  name: 'PackageTypeList',
  components: {
    IonList,
    IonItem,
    IonThumbnail,
    IonLabel,
    IonSkeletonText,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const firestore = firebase.firestore();
    const packages: Array<any> = [];

    const loading = ref(true);
    const show = ref(true);

    const loadPackages = async (registry: string): Promise<void> => {
      loading.value = true;
      packages.length = 0;

      const packagesSnapshot = await firestore
        .collection('package')
        .orderBy('lastUpdated', 'asc')
        .where('registry', '==', registry)
        .get();

      packagesSnapshot.forEach(_package => packages.push({uid: _package.id, ..._package.data()}));
      loading.value = false;
    };

    const openPackage = (uid: string): void => {
      router.replace(`/home/package/${uid}`);
    };

    const getImageForRegistry = (registry: string): string => {
      return `https://firebasestorage.googleapis.com/v0/b/project-release-2b33c.appspot.com/o/package-types%2F${registry}_64x64.png?alt=media`;
    };

    onBeforeMount(() => {
      const registry = route.params?.registry as string;

      if (registry) {
        loadPackages(registry);
      }
    });
    onBeforeRouteUpdate((_to, _from, _next) => {
      const registry = _to.params?.registry as string;

      show.value = false;

      setTimeout(() => {
        _next();

        if (registry) {
          loadPackages(registry);
        }

        show.value = true
      }, 200);
    });

    return {
      loading,
      show,
      packages,
      openPackage,
      getImageForRegistry,
    };
  }
});
