<template>
  <div :class="'content ' + (show ? 'fade-in' : 'fade-out')">
    <div v-if="!loading">
      <ion-list v-if="packages.length > 0">
        <ion-item v-for="_package in packages" :key="_package.uid"
                  button @click="openPackage(_package.uid)"
                  detail="true">
          <ion-thumbnail slot="start">
            <img :src="getImageForRegistry(_package.registry)">
          </ion-thumbnail>
          <ion-label>
            <h3> {{_package.name}} </h3>
            <p> {{_package.latestVersion}} </p>
          </ion-label>
        </ion-item>
      </ion-list>

      <div v-else class="nothing-found">
        <img src="/assets/nothing-found-light.gif" widht="384" height="384" />
        <div class="hint">
          Es wurgen keine Packete in der {{ $route?.params?.registry }} Registry gefunden
        </div>
      </div>
    </div>

    <div v-else class="list-skeleton">
      <ion-list>
        <ion-item v-for="x in (new Array(15))" :key="x">
          <ion-thumbnail slot="start">
            <ion-skeleton-text animated />
          </ion-thumbnail>
          <ion-label>
            <h3> <ion-skeleton-text animated style="width: 20%; height: 21px;" /> </h3>
            <p> <ion-skeleton-text animated style="width: 40%; height: 12px;" /> </p>
          </ion-label>
        </ion-item>
      </ion-list>
      <div class="inner-shadow" />
    </div>
  </div>
</template>

<script lang="ts" src="./package-list.ts" />
<style scoped src="./package-list.css" />
